<template>
	<div>
		<pui-datatable :modelName="modelName" :modelColumnDefs="modelColumnDefs"></pui-datatable>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'mports-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			modelName: 'mports',

			modelColumnDefs: {}
		};
	},
	methods: {}
};
</script>
